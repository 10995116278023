import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const Home = lazy(() => import('./Pages/Home'));
const About = lazy(() => import('./Pages/About'));
const Team = lazy(() => import('./Pages/Team'));
const Projects = lazy(() => import('./Pages/Projects'));
const Contact = lazy(() => import('./Pages/Contact'));
const GeorgetownOasis = lazy(() => import('./Pages/GeorgetownOasis'));
const HenryettaHills = lazy(() => import('./Pages/HenryettaHills'));
const SolanaIndustrialPark = lazy(() => import('./Pages/SolanaIndustrialPark'));
const MusicStreet = lazy(() => import('./Pages/MusicStreet'));
const PrivateAriportTechnologyCenter = lazy(() => import('./Pages/PrivateAriportTechnologyCenter'));
const GreenSustainableSmartCity = lazy(() => import('./Pages/GreenSustainableSmartCity'));
const LngPlantPipelinePort = lazy(() => import('./Pages/LngPlantPipelinePort'));
const MixedUseMasterPlannedDevelopment = lazy(() => import('./Pages/MixedUseMasterPlannedDevelopment'));
const NetZeroCommunity = lazy(() => import('./Pages/NetZeroCommunity'));
const Legal = lazy(() => import('./Pages/Legal'));
const Terms = lazy(() => import('./Pages/Terms'));
const Policy = lazy(() => import('./Pages/Policy'));


function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<LazyLoadingComponents Component={Home} />} />
        <Route path='/about' element={<LazyLoadingComponents Component={About} />} />
        <Route path='*' element={<LazyLoadingComponents Component={Home} />} />
        {/* <Route path='/team' element={<LazyLoadingComponents Component={Team} />} /> */}
        <Route path='/projects' element={<LazyLoadingComponents Component={Projects} />} />
        <Route path='/contact' element={<LazyLoadingComponents Component={Contact} />} />
        <Route path='/georgetownoasis' element={<LazyLoadingComponents Component={GeorgetownOasis} />} />
        <Route path='/henryettaHills' element={<LazyLoadingComponents Component={HenryettaHills} />} />
        <Route path='/solanaindustrialpark' element={<LazyLoadingComponents Component={SolanaIndustrialPark} />} />
        {/* <Route path='/musicstreet' element={<LazyLoadingComponents Component={MusicStreet} />} /> */}
        <Route path='/privateariporttechnologycenter' element={<LazyLoadingComponents Component={PrivateAriportTechnologyCenter} />} />
        {/* <Route path='/greensustainablesmartcity' element={<LazyLoadingComponents Component={GreenSustainableSmartCity} />} /> */}
        {/* <Route path='/lngplantpipelineport' element={<LazyLoadingComponents Component={LngPlantPipelinePort} />} /> */}
        {/* <Route path='/mixedusemasterplanneddevelopment' element={<LazyLoadingComponents Component={MixedUseMasterPlannedDevelopment} />} /> */}
        <Route path='/netzerocommunity' element={<LazyLoadingComponents Component={NetZeroCommunity} />} />
        <Route path='/legal' element={<LazyLoadingComponents Component={Legal} />} />
        <Route path='/termsofuse' element={<LazyLoadingComponents Component={Terms} />} />
        <Route path='/privacypolicy' element={<LazyLoadingComponents Component={Policy} />} />
      </Routes>
    </>
  );
}

export default App;

export function LazyLoadingComponents({ Component }) {
  return (
    <div>
      <Suspense fallback={<div></div>}>
        <Component />
      </Suspense>
    </div>
  );
}
